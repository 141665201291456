import {useState} from "react";
import {Row, Col, Drawer, Layout, Menu} from "antd";
import {withTranslation} from "react-i18next";
import {Icon} from "../../common/Icon";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
  MobileNavLinkSmall,
} from "./styles";
import {HOME_PATH} from "../../url";
import {MENU_LIST, MOBILE_MENU_LIST} from "../../menu-list";
import {useHistory, useLocation} from "react-router-dom";

const {Header} = Layout;

const HeaderView = ({t}: any): JSX.Element => {
  const {push} = useHistory();
  const {pathname} = useLocation();
  const [visible, setVisibility] = useState<boolean>(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const handleMenu = (path: string) => {
      setVisibility(false);
      push(path);
    };
    return (
      <>
        {MENU_LIST.map((el: any, idx: number) => (
          <CustomNavLinkSmall key={idx} onClick={() => handleMenu(el.path)}>
            <Span>{t(el.label)}</Span>
          </CustomNavLinkSmall>
        ))}
      </>
    );
  };

  const MiniMenuItem = () => {
    const handleMenu = (path: string) => {
      setVisibility(false);
      push(path);
    };
    return (
      <Row>
        <Col span={8} />
        {MOBILE_MENU_LIST.map((el: any, idx: number) => (
          <Col span={8} key={idx}>
            <MobileNavLinkSmall onClick={() => handleMenu(el.path)}>
              <Span>{t(el.label)}</Span>
            </MobileNavLinkSmall>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <HeaderSection id='top'>
      <NotHidden>
        <div id='top'></div>
        <Layout>
          <Header className='u-header'>
            <Row>
              <Col xl={6} lg={7} md={7} sm={5} className='u-align-self-center'>
                <LogoContainer to={HOME_PATH} aria-label='homepage'>
                  <Icon src='/png/logoJM.png' width='80%' height='auto' />
                </LogoContainer>
              </Col>
              <Col xl={18} lg={17} md={17} sm={19}>
                <Menu
                  // mode='horizontal'
                  selectedKeys={["/" + pathname.split("/")[1]]}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {MENU_LIST.map((el: any, idx: number) => (
                    <Menu.Item
                      key={el?.path}
                      className='u-menu-item'
                      onClick={() => push(el?.path)}
                    >
                      {el?.label}
                    </Menu.Item>
                  ))}
                </Menu>
              </Col>
            </Row>
          </Header>
        </Layout>
      </NotHidden>

      <Burger>
        <Row justify='space-between'>
          <Col span={3}>
            <LogoContainer to={HOME_PATH} aria-label='homepage'>
              <Icon src='/icons/logoJM.png' width='60px' height='32px' />
            </LogoContainer>
          </Col>
          <Col span={16}>
            <MiniMenuItem />
          </Col>
          <Col span={3}>
            <Outline onClick={showDrawer} />
          </Col>
        </Row>
      </Burger>

      <Drawer closable={false} visible={visible} onClose={onClose}>
        <Col style={{marginBottom: "2.5rem"}}>
          <Label onClick={onClose}>
            <Col span={12}>
              <Menu>Menu</Menu>
            </Col>
            <Col span={12}>
              <Outline />
            </Col>
          </Label>
        </Col>
        <MenuItem />
      </Drawer>

      {/* <NotHidden>
          <Row justify='space-between'>
            <Col lg={4} md={4} sm={3}>
              <LogoContainer to={HOME_PATH} aria-label='homepage'>
                <Icon src='/icons/logoJM.png' width='101px' height='64px' />
              </LogoContainer>
            </Col>
            <Col lg={20} md={20} sm={21} className='u-text-right'>
              <MenuItem />
            </Col>
          </Row>
        </NotHidden>
        <Burger>
          <Row justify='space-between'>
            <Col span={4}>
              <LogoContainer to={HOME_PATH} aria-label='homepage'>
                <Icon src='/icons/logoJM.png' width='60px' height='32px' />
              </LogoContainer>
            </Col>
            <Col span={15}>
              <MiniMenuItem />
            </Col>
            <Col span={3}>
              <Outline onClick={showDrawer} />
            </Col>
          </Row>
        </Burger>
        <Drawer closable={false} visible={visible} onClose={onClose}>
          <Col style={{marginBottom: "2.5rem"}}>
            <Label onClick={onClose}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer> 
      </Container> */}
    </HeaderSection>
  );
};

export default withTranslation()(HeaderView);
