import {Row, Col} from "antd";
import {withTranslation} from "react-i18next";
import Container from "../../common/Container";

// import i18n from "i18next";
import {
  FooterSection,
  Title,
  Text,
  MobileText,
  BoldText,
  MobileBoldText,
  Header,
  MobileHeader,
  HeaderWrap,
  Span,
  MobileSpan,
  MobileAddressText,
  Copyright,
  AnchorText,
  // NavLink,
  // Language,
  // Label,
  // LanguageSwitch,
  // LanguageSwitchContainer,
} from "./styles";
import {
  FACTORY_ADDRESS,
  HEAD_OFFICE_ADDRESS,
  MOBILE_FACTORY_ADDRESS,
  MOBILE_HEAD_OFFICE_ADDRESS,
  PAGE_PRODUCT_LIST,
  STOCK_POINT_LIST,
} from "../../constants";
import {DISCUSS_PATH, FACILITIES_PATH, PRODUCT_DETAIL_PATH} from "../../url";
import {Link, useHistory} from "react-router-dom";
import {MENU_LIST, MOBILE_COMPANY_LIST} from "../../menu-list";
import {MobileView, WebView} from "../../styles/styles";

const Footer = ({t}: any): JSX.Element => {
  // const handleChange = (language: string) => {
  //   i18n.changeLanguage(language);
  // };

  const {push} = useHistory();

  return (
    <>
      <FooterSection>
        <Container>
          <WebView>
            <HeaderWrap>
              <Header>
                Want to discuss with Us ?{" "}
                <Span onClick={() => push(DISCUSS_PATH)}>Contact Us</Span>
              </Header>
            </HeaderWrap>
            <Row gutter={50}>
              <Col lg={9} md={12} sm={12} xs={12}>
                <div className='u-pb2'>
                  <Title>{t("Head Office")}</Title>
                  {HEAD_OFFICE_ADDRESS.split(";").map(
                    (el: string, idx: number) => (
                      <Text address={true} key={idx}>
                        {el}
                      </Text>
                    ),
                  )}
                </div>
                <div className='u-pb2'>
                  <Title>{t("Blending Point")}</Title>
                  {FACTORY_ADDRESS.split(";").map((el: string, idx: number) => (
                    <Text address={true} key={idx}>
                      {el}
                    </Text>
                  ))}
                </div>
                <Title>{t("Contact Us")}</Title>
                <Text>Email : sales@jmmutuutama.co.id</Text>
                <Text>Phone Number : +622153164414 - 16</Text>
              </Col>

              <Col lg={5} md={12} sm={12} xs={12}>
                <Title>{t("Warehouse")}</Title>
                {STOCK_POINT_LIST.map((el: any, idx: number) => (
                  <div key={idx}>
                    <BoldText>{el.city}</BoldText>
                    <Row>
                      <Col span={11}>
                        <Link
                          to={{
                            pathname: FACILITIES_PATH,
                            state: {
                              key: String(el.district[0].key),
                            },
                          }}
                        >
                          <AnchorText>{el.district[0].name}</AnchorText>
                        </Link>
                      </Col>
                      {el.district.length === 2 && (
                        <>
                          <Col span={2}>
                            <Text>|</Text>
                          </Col>
                          <Col span={11}>
                            <Link
                              to={{
                                pathname: FACILITIES_PATH,
                                state: {
                                  key: String(el.district[1].key),
                                },
                              }}
                            >
                              <AnchorText>{el.district[1].name}</AnchorText>
                            </Link>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                ))}
              </Col>

              <Col lg={5} md={12} sm={12} xs={12}>
                <Title>{t("Product")}</Title>
                {PAGE_PRODUCT_LIST.map((el: any, idx: number) => (
                  <div key={idx}>
                    <AnchorText
                      onClick={() => push(`${PRODUCT_DETAIL_PATH}/${el.key}`)}
                    >
                      {el.title}
                    </AnchorText>
                  </div>
                ))}
              </Col>

              <Col lg={5} md={12} sm={12} xs={12}>
                <Title>{t("Company")}</Title>
                {MENU_LIST?.map((el: any, idx: number) => (
                  <div key={idx}>
                    <AnchorText onClick={() => push(el.path)}>
                      {el.label}
                    </AnchorText>
                  </div>
                ))}
              </Col>
              <Copyright>Copyright © 2022 PT JM Mutu Utama</Copyright>
            </Row>
          </WebView>

          <MobileView>
            <Row gutter={16}>
              <Col lg={8} md={8} sm={16} xs={16}>
                <MobileHeader>Want to discuss with Us?</MobileHeader>
              </Col>
              <Col lg={4} md={4} sm={8} xs={8}>
                <MobileSpan onClick={() => push(DISCUSS_PATH)}>
                  Contact Us
                </MobileSpan>
              </Col>
              <Col lg={12} md={12} sm={0} xs={0} />
              <Col span={12}>
                <Title>{t("Warehouse")}</Title>
                {STOCK_POINT_LIST.map((el: any, idx: number) => (
                  <div key={idx}>
                    <MobileBoldText>{el.city}</MobileBoldText>
                    {el.district.length === 1 && (
                      <AnchorText
                        onClick={() =>
                          push(`${FACILITIES_PATH}/${el.district[0].key}`)
                        }
                      >
                        {el.district[0].name}
                      </AnchorText>
                    )}
                    {el.district.length === 2 && (
                      <Row>
                        <Col span={11}>
                          <AnchorText
                            onClick={() =>
                              push(`${FACILITIES_PATH}/${el.district[0].key}`)
                            }
                          >
                            {el.district[0].name}
                          </AnchorText>
                        </Col>
                        <Col span={2}>
                          <MobileText>|</MobileText>
                        </Col>
                        <Col span={11}>
                          <AnchorText
                            onClick={() =>
                              push(`${FACILITIES_PATH}/${el.district[1].key}`)
                            }
                          >
                            {el.district[1].name}
                          </AnchorText>
                        </Col>
                      </Row>
                    )}
                  </div>
                ))}
              </Col>
              <Col span={12}>
                <Title>{t("Product")}</Title>
                {PAGE_PRODUCT_LIST.map((el: any, idx: number) => (
                  <div key={idx}>
                    <AnchorText
                      onClick={() => push(`${PRODUCT_DETAIL_PATH}/${el.key}`)}
                    >
                      {el.title}
                    </AnchorText>
                  </div>
                ))}
              </Col>

              <Col span={24}>
                <Title>{t("Company")}</Title>
              </Col>

              <Col span={12}>
                {MOBILE_COMPANY_LIST[0]?.map((el: any, idx: number) => (
                  <div key={idx}>
                    <AnchorText onClick={() => push(el.path)}>
                      {el.label}
                    </AnchorText>
                  </div>
                ))}
              </Col>
              <Col span={12}>
                {MOBILE_COMPANY_LIST[1]?.map((el: any, idx: number) => (
                  <div key={idx}>
                    <AnchorText onClick={() => push(el.path)}>
                      {el.label}
                    </AnchorText>
                  </div>
                ))}
              </Col>

              <Col lg={12} md={12} xs={24} sm={24} className='u-pt3'>
                <Title>{t("Head Office")}</Title>
                {MOBILE_HEAD_OFFICE_ADDRESS.split(";").map(
                  (el: any, idx: number) => (
                    <div key={idx}>
                      <MobileAddressText>{el}</MobileAddressText>
                    </div>
                  ),
                )}
              </Col>

              <Col lg={12} md={12} xs={24} sm={24} className='u-pt3'>
                <Title>{t("Factory")}</Title>
                {MOBILE_FACTORY_ADDRESS.split(";").map(
                  (el: any, idx: number) => (
                    <div key={idx}>
                      <MobileAddressText>{el}</MobileAddressText>
                    </div>
                  ),
                )}
              </Col>
              <Copyright>Copyright © 2022 PT JM Mutu Utama</Copyright>
            </Row>
          </MobileView>

          {/* FOR FURTHER DEVELOPMENT
            <Col lg={6} md={6} sm={12} xs={12}>
            <Label htmlFor='select-lang'>{t("Language")}</Label>
            <LanguageSwitchContainer>
              <LanguageSwitch onClick={() => handleChange("en")}>
                <Icon
                  src='united-states.svg'
                  aria-label='homepage'
                  width='30px'
                  height='30px'
                />
              </LanguageSwitch>
              <LanguageSwitch onClick={() => handleChange("es")}>
                <Icon
                  src='spain.svg'
                  aria-label='homepage'
                  width='30px'
                  height='30px'
                />
              </LanguageSwitch>
            </LanguageSwitchContainer>
          </Col> */}
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
