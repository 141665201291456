import {
  ABOUT_US_PATH,
  CAREER_PATH,
  DISCUSS_PATH,
  FACILITIES_PATH,
  HOME_PATH,
  NEWS_PATH,
  PRODUCT_DETAIL_PATH,
  PRODUCT_PATH,
} from "../url";

const routes = [
  {
    path: HOME_PATH,
    exact: true,
    component: "Home",
  },
  {
    path: ABOUT_US_PATH,
    exact: true,
    component: "AboutUs",
  },
  {
    path: CAREER_PATH,
    exact: true,
    component: "Career",
  },
  {
    path: PRODUCT_PATH,
    exact: true,
    component: "Products/ProductsList",
  },
  {
    path: `${PRODUCT_DETAIL_PATH}/:id`,
    exact: true,
    component: "Products/ProductsDetail",
  },
  {
    path: FACILITIES_PATH,
    exact: true,
    component: "Facilities",
  },
  {
    path: `${FACILITIES_PATH}/:id`,
    exact: true,
    component: "Facilities/FacilitiesDetail",
  },
  {
    path: NEWS_PATH,
    exact: true,
    component: "News",
  },
  {
    path: DISCUSS_PATH,
    exact: true,
    component: "DiscussForm",
  },
];

export default routes;
