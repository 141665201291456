interface ICareerList {
  title: string;
  subtitle: string;
  date: string;
  key: number;
}

interface ICareerListDropdown {
  label: string;
  value: number;
}
interface IProductList {
  title: string;
  subtitle: string;
  key: number;
}

// ------------------------------------ FOOTER ------------------------------------
export const HEAD_OFFICE_ADDRESS: string =
  "Central Business District (CBD) BSD City,; Bidex Blok I No. 7-8, Tangerang Selatan, 15321,; Banten, Indonesia";

export const MOBILE_HEAD_OFFICE_ADDRESS: string = `Central Business District (CBD);
BSD City, Bidex Blok I No. 7-8;
Tangerang Selatan, Banten, 15321;
Telp: (62-21) 5316 4414-16;
Fax : (62-21) 5316 4420;
E-mail: sales@jmmutuutama.co.id`;

export const FACTORY_ADDRESS: string =
  "Kawasan Industri Modern Cikande; Jl. Modern Industri III No. 5, Cikande,; Serang, Banten, 42186";

export const MOBILE_FACTORY_ADDRESS: string = `Kawasan Industri Modern Cikande;
Jl. Modern Industri III No. 5;
Cikande, Serang, Banten, 42186;
Telp: (62-254) 403 844-6;
Fax: (62-254) 403 847;
Website: www.jmmutuutama.co.id`;

export const STOCK_POINT_LIST: any[] = [
  {
    city: "Jakarta",
    district: [
      {name: "Marunda", key: 2},
      {name: "Cikande", key: 2},
    ],
  },
  {
    city: "Kalimantan Timur",
    district: [
      {name: "Samarinda", key: 5},
      {name: "Balikpapan", key: 4},
    ],
  },
  {
    city: "Sumatera",
    district: [
      {name: "Duri", key: 1},
      {name: "Medan", key: 0},
    ],
  },
  {
    city: "Jawa Timur",
    district: [{name: "Surabaya", key: 3}],
  },
];

// ------------------------------------ HOME ------------------------------------

export const CORE_LIST: any[] = [
  {
    name: "Best Services",
    description:
      "Providing the best services through qualified suppliers, on time delivery and competitive price.",
    path: "/png/number1.svg",
  },
  {
    name: "Sustainable",
    description:
      "Doing business responsibly to achieve profit with long term growth.",
    path: "/png/number2.svg",
  },
  {
    name: "Solutive",
    description:
      "Seeking better welfare for shareholders, employees and environments.",
    path: "/png/number3.svg",
  },
];

export const SERVICE_INTRODUCTION: string = `
We’ve taken every services to heart, applying our experience and expertise to meet and exceed the demands for responsibly produced products.;
From high-quality products and specialty chemicals to sophisticated drilling technologies with our attention to safety and also sustainability of our performance.;
We’re leading the way with energy and chemical products designed to perform, and produced with care.
`;

export const STAKEHOLDER_LIST: any[] = [
  "/png/stakeholder1.png",
  "/png/stakeholder2.png",
  "/png/stakeholder3.png",
  "/png/stakeholder4.png",
  "/png/stakeholder5.png",
  "/png/stakeholder6.png",
  "/png/stakeholder7.png",
  "/png/stakeholder8.png",
  "/png/stakeholder9.png",
  "/png/stakeholder10.png",
  "/png/stakeholder11.png",
  "/png/stakeholder12.png",
  "/png/stakeholder13.png",
  "/png/stakeholder14.png",
  "/png/stakeholder15.png",
  "/png/stakeholder16.png",
];

// ------------------------------------ ABOUT_US ------------------------------------

export const PROFILE_INFO: string = `PT. JM Mutu Utama is a national private company that established in 2000 which produces supporting chemicals for oil and gas, mining, fertilizer and industrial activities throughout Indonesia.;
We provide excellent service, operation and maintenance, procurement, logistics and chemical. We are committed to providing services in safe, innovative and sustainable solutions for the challenges in the oil and gas industry.;
We always give careful attention and responsibilites to our services and produce what the national industry needs, creating vast career opportunities, because we care about each other, our customers and our community.
`;

export const PROFILE_PERSON: any[] = [
  {
    name: "Jakaria Umar",
    description: "Managing Director",
    path: "/png/about_us_3.png",
  },
  {
    name: "M. Ali Umar",
    description: "Director",
    path: "/png/about_us_2.png",
  },
  {
    name: "Titin Marsitin",
    description: "Commissioner",
    path: "/png/about_us_4.png",
  },
  {
    name: "Fatmah",
    description: "Commissioner",
    path: "/png/about_us_5.png",
  },
];

export const ISO_INFO: string = `
We are highly competent and comply with national rules and regulations in Indonesia. Support entrusted partners to working professionally in order to provide the best services for clients. We always continue to build networks and collaborations to accelerate the procurement process by generating new innovations to produce products and services efficiently.;
Health, safety, security and the environment is our main priority. We are commited to expand our business to provide fully integrated services and projects that add value to our clients, people, and industry.;
PT. JM Quality Utama has ISO Standard certification as follows:
`;

export const ISO_DETAIL_INFO: string = `
ISO 9001:2015 Certificate QEC21768;
ISO 14001:2015 Certificate CEM21366;
ISO 45001:2018 Certificate OHS21593
`;

export const CERTIFICATE_INFO: any[] = [
  "/png/iso_certificate_1.png",
  "/png/iso_certificate_2.png",
  "/png/iso_certificate_3.png",
];

export const CERTIFIED_INFO: any[] = [
  "/png/certified_1.png",
  "/png/certified_2.png",
];

export const CAROUSEL_LIST: string[] = [
  "png/carousel_1.png",
  // "png/carousel_2.png",
  // "png/carousel_3.png",
  "png/carousel_4.png",
  "png/carousel_5.png",
  "png/carousel_6.png",
  "png/carousel_7.png",
  "png/carousel_8.png",
  "png/facilities_2.png",
  // "png/carousel_10.png",
];

// ------------------------------------ CAREER ------------------------------------
export const CAREER_LIST: ICareerList[] = [
  // {
  //   title: "Mining Surveyor",
  //   subtitle:
  //     "A vacant position as Mining Surveyor has arisen in Coal Mine Project, East Kalimantan.",
  //   date: "01 Februari 2022",
  //   key: 2,
  // },
  // {
  //   title: "Diesel Fitter",
  //   subtitle:
  //     "JM Mutu Utama currently have an opportunity for experienced Heavy Diesel Fitters to join the team at Samarinda.",
  //   date: "01 Februari 2022",
  //   key: 2,
  // },
  // {
  //   title: "Human Resources Advisor",
  //   subtitle:
  //     "Thiess currently have a fantastic opportunity available for an HR Advisor at Head Office.",
  //   date: "01 Februari 2022",
  //   key: 10,
  // },
];

export const CAREER_LIST_DROPDOWN: ICareerListDropdown[] = [
  {
    label: "Semua Lowongan",
    value: 0,
  },
  {
    label: "Administration",
    value: 1,
  },
  {
    label: "Mining Operations",
    value: 2,
  },
  {
    label: "Engineering - Chemical",
    value: 3,
  },
  {
    label: "Health Savety",
    value: 4,
  },
  {
    label: "Engineering - Mechanical",
    value: 5,
  },
  {
    label: "Electrical Team",
    value: 6,
  },
  {
    label: "Human Resources",
    value: 7,
  },
  {
    label: "Construction Team",
    value: 8,
  },
  {
    label: "Surveying",
    value: 9,
  },
  {
    label: "Human Resources",
    value: 10,
  },
];

// ------------------------------------ PRODUCT ------------------------------------
export const PAGE_PRODUCT_LIST: IProductList[] = [
  {
    title: "Drilling Chemicals",
    subtitle:
      "We are one of the companies that produce supporting chemicals in oil and gas drilling activities throughout Indonesia.",
    key: 1,
  },
  {
    title: "Mining Chemicals",
    subtitle:
      "We provide various kinds of chemical products for mining processes. The products provided in the gold mining process can be classified into four categories of work stages.",
    key: 2,
  },
  {
    title: "Cementing Chemicals",
    subtitle:
      "Cementing a well is one of the most important factors in a drilling operation. The success or failure of drilling, among others, depends on the success or failure of the cementing of the well.",
    key: 3,
  },
  {
    title: "Water and Wastewater Treatment",
    subtitle:
      "We provide quality chemicals to assist in the processing and purification of clean water and wastewater treatment.",
    key: 4,
  },
  {
    title: "Stimulation Chemicals",
    subtitle:
      "Stimulation is stimulating the well which is a process of repairing the well to increase the permeability of the damaged formation so that it can provide a large production rate.",
    key: 5,
  },
  {
    title: "Mechanical Filtration",
    subtitle:
      "Holding an exclusive distributor of world-class refractory manufacturers from UK and Spain, PT JM Mutu Utama has more than 10 years of experience in supplying high-quality products and performance to the steel industry and cement plant in Indonesia.",
    key: 6,
  },
  {
    title: "Production Chemicals",
    subtitle:
      "We provide a variety of chemicals that can be used in the production process of petroleum processing, through a precise and accurate selection process.",
    key: 7,
  },
  {
    title: "Refractories",
    subtitle:
      "We provide various kinds of products to support the fertilizer and cement industry, power plants.",
    key: 8,
  },
];

// ------------------------------------ PRODUCTS DETAIL ------------------------------------
export const PRODUCT_DETAILS_DATA: any[] = [
  {
    key: 1,
    title: "Drilling Chemicals",
    description:
      "We are one of the companies that produce supporting chemicals in oil and gas drilling activities throughout Indonesia. The chemicals required in oil and gas drilling activities, both for offshore and onshore drilling are as follows:",
    list: [
      "Cleaning Fluids",
      "Surfactant",
      "Corrosion Inhibitor",
      "Thickening Agent",
      "Lubricants",
      "Fluid Loss",
      "Viscosifiers",
      "pH Control",
      "Clay Stabilizer",
      "Primary Emulsifier",
      "Shale Inhibitor",
      "Secondary Emulsifier",
      "Weighting Agent",
    ],
  },
  {
    key: 2,
    title: "Mining Chemicals",
    description:
      "We provide various kinds of chemical products for mining processes. The products provided in the gold mining process can be classified into four categories of work stages as follows:",
    list: ["Blasting", "Flotation", "Leaching", "Dewatering"],
  },
  {
    key: 3,
    title: "Cementing Chemicals",
    description:
      "Well cementing is one of the most important factors in a drilling operation. The success or failure of a drilling, among others, depends on the success or failure of the cementing of the well. We provides excellent and competitive chemicals for cementing, namely:",
    list: [
      "Cement Accelerator",
      "Cement Class G",
      "Cement Extender",
      "Anti Gas Migration",
      "Cement Retarder",
      "Defoamer",
      "Expanding Agent",
      "Thixotropic Agent",
      "Cement Dispersant",
      "Spacer",
      "Fluid Loss Additive",
      "Low Density Agent",
    ],
  },
  {
    key: 4,
    title: "Water & Wastewater Treatment Chemicals",
    description: `There are various kinds of technology and methods used in wastewater treatment systems, one of which is the Chemical and Physical Treatment system. What distinguishes it from the Extended Aeration Biology System is if Biology uses aerobic bacteria. This system applies the function of adding coagulant in the chemical water purification process. This chemical water purification method is by adding coagulants and flocculants as rectors and mixing is carried out to bind chemical and heavy metal content in the wastewater.;
    We provide quality chemicals to assist in the processing and purification of clean water and wastewater treatment:`,
    list: ["Coagulant Aid", "Biocide", "Flocculant Aid"],
  },
  {
    key: 5,
    title: "Stimulation Chemical",
    description: `Stimulation is stimulating the well which is a process of repairing the well to increase the permeability of the damaged formation so that it can provide a large production rate, which in the end the productivity of the well will be greater than before the well stimulation was held. Stimulation is carried out on production wells that have decreased production due to formation damage around the wellbore by improving the permeability of the reservoir rock. Stimulation methods can be divided into Acidizing and Hydraulic Fracturing.;
    We provide various kinds of chemicals in well stimulation activities, namely:`,
    list: [
      "Acid Corrosion",
      "Iron Control Agent",
      "Anti Sludge Agent",
      "Oxygen Scavenger",
      "Non-Emulsifier",
      "Scale Remover",
      "H2S Scavenger",
      "Scale Inhibitor",
      "Surfactant",
      "Corrosion Inhibitor",
      "Clay Stabilizer",
      "Chelating Agent",
      "Acid Retarder",
      "Proppant",
      "Mutual Solvent",
    ],
  },
  {
    // HARDCODED KEY FOR UI
    key: 6,
    title: "Mechanical Filtration",
    description: `We provide various kinds of products to support the fertilizer and cement industry, power plants including:`,
    list: [
      "Rotary kiln, dryer, granulator, cooler and coater unit equipment supply & services",
      "Rotary drum / kiln part : girth gear, pinnion gear, riding ring / tyre, support roller and trust roller",
      "Rotating equipment services (rotary drum,  gearbox and pump)",
      "Heat exchanger",
      "Pressure vessel",
      "Piping system : special valve (control and manual valve) with exotic materials",
      "Equipment filters",
    ],
  },
  {
    key: 7,
    title: "Production Chemicals",
    description: `Problems that exist in the oil and gas field such as oil that easily freezes, many scaling problems, corrosion, low oil quality, a lot of waste water that does not meet environmental safety requirements, all of which can occur and require chemical solutions.;
    We provide a variety of chemicals that can be used in the production process of petroleum processing, through a precise and accurate selection process. The chemicals used in maximizing to provide the best solution are as follows:`,
    list: [
      "Reverse Demulsifier",
      "Oil Spill Dispersant",
      "Demulsifier",
      "Oxygen Scavenger",
      "Scale Inhibitor",
      "H2S Scavenger",
      "Scale Remover",
      "Microbiocide",
      "Corrosion Inhibitor",
      "Cleaners",
      "Paraffin Solvent",
      "Foamer",
      "Pour Point Depressant",
      "Defoamer/Anti-foam",
    ],
  },
  {
    // HARDCODED KEY FOR UI
    key: 8,
    title: "Refractories",
    description: [
      `Holding an exclusive distributor of world class refractory manufacturer from UK and Spain, PT. JM Mutu Utama has more than 10 years experience in supplying high quality product and performance to the steel industry and cement plant in Indonesia.;`,
      `1. Steel Industry;
    We supply Mayerton Refractories products including bricks and magnesia mass for the EAF, ladles and tundishes.;;
    2. Cement Industry;
    We supply Refracta products including bricks and castable for critical area at cement plant such as inlet chamber, cooler, kiln upper transition zone and outlet zone. We supply:`,
    ],
    listTitle: ["Cement Industry Supply"],
    list: [
      ["Inlet Chamber", "Cooler", "Klin Upper Transition Zone", "Outlet Zone"],
    ],
  },
];

// ------------------------------------ FACILITIES ------------------------------------
export const POSITION: any[] = [
  {
    left: "7%",
    top: "17%",
    selectedLeft: "5%",
    selectedTop: "9.2%",
    name: "Medan",
    detail: {
      left: "-2.1%",
      top: "-13.9%",
      width: "20%",
      path: "/png/medan_facilities.svg",
    },
    list: [
      {
        title: "Warehouse",
        address: `
        Jalan Pulau Nias, Kompleks Pergudangan Paya Rumput 2;
        Gudang No. 11, Kel. Titi Papan;
        Kec. Medan Deli, Sumatera Utara
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    left: "12.1%",
    top: "26%",
    selectedLeft: "10.1%",
    selectedTop: "18.4%",
    name: "Duri",
    detail: {
      left: "3.1%",
      top: "-4.9%",
      width: "20%",
      path: "/png/duri_facilities.svg",
    },
    list: [
      {
        title: "Warehouse",
        address: `
        Jalan Lintas Duri Dumai KM 8, Desa Balemakam;
        Kec. Mandu, Kab. Bengkalis;
        Kota Duri, Provinsi Riau
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    left: "24.3%",
    top: "68.2%",
    selectedLeft: "22.4%",
    selectedTop: "61.1%",
    name: "Jabodetabek",
    detail: {
      left: "5.6%",
      top: "13%",
      width: "39%",
      path: "/png/jabodetabek_facilities.svg",
    },
    list: [
      {
        title: "Headquarter",
        address: `
      Central Business District (CBD);
      BSD City, Bidex Blok I No. 7-8;
      Tangerang Selatan 15321, Banten Indonesia
      `,
        path: "/png/facilities_1.png",
        gmaps: "https://goo.gl/maps/6ZYYLedKE3YcmaGn6",
      },
      {
        title: "Blending Plant",
        address: `
        Kawasan Industri Modern Cikande;
        Jl. Modern Industri III No. 5;
        Cikande, Serang, Banten 42186
      `,
        path: "/png/facilities_2.png",
        gmaps: "https://goo.gl/maps/EqAJ2fysCnwrcqgZ7",
      },
      {
        title: "Warehouse",
        address: `
        Jl. Raya PLTGU Muara Tawar No. 101;
        Desa Pantai Makmur, Kec. Tarumajaya;
        Kab. Bekasi Utara, 17212
      `,
        path: "/png/facilities_3.png",
        gmaps: "https://goo.gl/maps/t3QsrBiz7GbSZZ4W9",
      },
    ],
  },
  {
    left: "36.5%",
    top: "74%",
    selectedLeft: "34.5%",
    selectedTop: "66.3%",
    name: "Surabaya",
    detail: {
      left: "27.4%",
      top: "42.9%",
      width: "20%",
      path: "/png/surabaya_facilities.svg",
    },
    list: [
      {
        title: "Warehouse",
        address: `
        Jalan Raya Sukomanunggal No. 179 Blok C2;
        Kota Surabaya, Jawa Timur
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    left: "46.6%",
    top: "39%",
    selectedLeft: "44.5%",
    selectedTop: "31.9%",
    name: "Balikpapan",
    detail: {
      left: "37.5%",
      top: "8.3%",
      width: "20%",
      path: "/png/balikpapan_facilities.svg",
    },
    list: [
      {
        title: "Warehouse",
        address: `
        Jalan A. Wahab Syahrani RT 03 No. 47;
        Somber, Kec. Balikpapan Utara;
        Kota Balikpapan, Kalimantan Timur
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    left: "47.8%",
    top: "30%",
    selectedLeft: "45.8%",
    selectedTop: "22%",
    name: "Samarinda",
    detail: {
      left: "38.8%",
      top: "-1.1%",
      width: "20%",
      path: "/png/samarinda_facilities.svg",
    },
    list: [
      {
        title: "Warehouse",
        address: `
        Jalan Teuku Umar;
        Kompleks Pergudangan Baru Tahap III Blok D No. 2;
        Kec. Sungai Kunjang;
        Samarinda, Kalimantan Timur
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
];

export const FACILITIES_MOBILE_LIST: any[] = [
  {
    name: "Medan",
    mapsPath: "/png/maps_mobile_medan.png",
    facilities: [
      {
        title: "Warehouse",
        address: `
        Jalan Pulau Nias, Kompleks Pergudangan Paya Rumput 2;
        Gudang No. 11, Kel. Titi Papan;
        Kec. Medan Deli, Sumatera Utara
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    name: "Duri",
    mapsPath: "/png/maps_mobile_duri.png",
    facilities: [
      {
        title: "Warehouse",
        address: `
        Jalan Lintas Duri Dumai KM 8, Desa Balemakam;
        Kec. Mandu, Kab. Bengkalis;
        Kota Duri, Provinsi Riau
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    name: "Jabodetabek",
    mapsPath: "/png/maps_mobile_jabodetabek.png",
    facilities: [
      {
        title: "Headquarter",
        address: `
      Central Business District (CBD);
      BSD City, Bidex Blok I No. 7-8;
      Tangerang Selatan 15321, Banten Indonesia.
      `,
        path: "/png/facilities_1.png",
        gmaps: "https://goo.gl/maps/6ZYYLedKE3YcmaGn6",
      },
      {
        title: "Blending Plant",
        address: `
        Kawasan Industri Modern Cikande;
        Jl. Modern Industri III No. 5;
        Cikande, Serang, Banten 42186
      `,
        path: "/png/facilities_2.png",
        gmaps: "https://goo.gl/maps/EqAJ2fysCnwrcqgZ7",
      },
      {
        title: "Warehouse",
        address: `
        Jl. Raya PLTGU Muara Tawar No. 101;
        Desa Pantai Makmur, Kec. Tarumajaya;
        Kab. Bekasi Utara, 17212
      `,
        path: "/png/facilities_3.png",
        gmaps: "https://goo.gl/maps/t3QsrBiz7GbSZZ4W9",
      },
    ],
  },
  {
    name: "Surabaya",
    mapsPath: "/png/maps_mobile_surabaya.png",
    facilities: [
      {
        title: "Warehouse",
        address: `
        Jalan Raya Sukomanunggal No. 179 Blok C2;
        Kota Surabaya, Jawa Timur
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    name: "Balikpapan",
    mapsPath: "/png/maps_mobile_balikpapan.png",
    facilities: [
      {
        title: "Warehouse",
        address: `
        Jalan A. Wahab Syahrani RT 03 No. 47;
        Somber, Kec. Balikpapan Utara;
        Kota Balikpapan, Kalimantan Timur
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
  {
    name: "Samarinda",
    mapsPath: "/png/maps_mobile_samarinda.png",
    facilities: [
      {
        title: "Warehouse",
        address: `
        Jalan Teuku Umar;
        Kompleks Pergudangan Baru Tahap III Blok D No. 2;
        Kec. Sungai Kunjang;
        Samarinda, Kalimantan Timur
      `,
        path: null,
        gmaps: "",
      },
    ],
  },
];

// ------------------------------------ NEWS ------------------------------------
export const NEWS_LIST: any[] = [
  // {
  //   title: "Shaking Drilling",
  //   description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //   Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation`,
  //   path: "/png/facilities_2.png",
  // },
  // {
  //   title: "Shaking Drilling",
  //   description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //   Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation`,
  //   path: "/png/facilities_1.png",
  // },
  // {
  //   title: "Shaking Drilling",
  //   description: `Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
  //   Exercitation veniam consequat sunt nostrud amet.Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation`,
  //   path: "/png/facilities_3.png",
  // },
];
