import {IconProps} from "../types";

export const Icon = ({
  src,
  width,
  height,
  className,
  style,
  onClick,
}: IconProps) => (
  <img
    src={`/img/${src}`}
    alt={src}
    width={width}
    height={height}
    className={className}
    style={style}
    onClick={onClick}
  />
);
