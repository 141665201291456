import {
  ABOUT_US_PATH,
  CAREER_PATH,
  FACILITIES_PATH,
  HOME_PATH,
  NEWS_PATH,
  PRODUCT_PATH,
} from "./url";

export const MENU_LIST = [
  {
    label: "Home",
    path: HOME_PATH,
  },
  {
    label: "Our Product",
    path: PRODUCT_PATH,
  },
  {
    label: "Our Facilities",
    path: FACILITIES_PATH,
  },
  {
    label: "About Us",
    path: ABOUT_US_PATH,
  },
  {
    label: "Career",
    path: CAREER_PATH,
  },
  {
    label: "News",
    path: NEWS_PATH,
  },
];

export const MOBILE_MENU_LIST = [
  {
    label: "About Us",
    path: ABOUT_US_PATH,
  },
  {
    label: "Our Product",
    path: PRODUCT_PATH,
  },
];

export const MOBILE_COMPANY_LIST: any[] = [
  [
    {
      label: "About Us",
      path: ABOUT_US_PATH,
    },
    {
      label: "Career",
      path: CAREER_PATH,
    },
    {
      label: "News",
      path: NEWS_PATH,
    },
  ],
  [
    {
      label: "Our Product",
      path: PRODUCT_PATH,
    },
    {
      label: "Our Facilities",
      path: FACILITIES_PATH,
    },
  ],
];
