import styled from "styled-components";
import {Link} from "react-router-dom";

export const FooterSection = styled("footer")`
  background: #313131;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
`;

export const HeaderWrap = styled("div")`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 2rem;
  padding-bottom: 4rem;
`;

export const Header = styled("p")`
  font-size: 34px;
  color: #ffffff;
  font-family: Inter Bold;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const Title = styled("p")`
  font-size: 24px;
  text-transform: capitalize;
  color: #ffffff;
  padding-bottom: 1rem;
  margin-bottom: 0;
  font-family: Inter Bold;

  @media screen and (max-width: 414px) {
    padding: 2rem 0 1rem 0;
    letter-spacing: -0.5px;
  }
`;

export const Text = styled("p")<any>`
  font-size: 16px;
  color: #ffffff;
  ${(p) => (p.address ? "margin: 0;" : "")}
  ${(p) => (p.address ? "padding: 0;" : "")}
`;

export const AnchorText = styled("p")<any>`
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  :hover {
    color: #ffb600;
  }
  ${(p) => (p.address ? "margin: 0;" : "")}
  ${(p) => (p.address ? "padding: 0;" : "")}
`;

export const BoldText = styled("p")`
  font-size: 17px;
  text-transform: capitalize;
  color: #ffffff;
  line-height: 32px;
  padding: 0;
  margin: 0;
  font-family: Inter Bold;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const NavLink = styled(Link)`
  display: block;
  font-size: 1rem;
  margin-bottom: 0.625rem;
  transition: all 0.2s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    color: #15418e;
  }
`;

export const Span = styled("a")`
  font-size: 34px;
  text-transform: capitalize;
  color: #ffb600;
  cursor: pointer;
  border-bottom: 1px solid;
  text-decoration: none;
  letter-spacing: -0.5px;
  transition: all 0.3s ease-in-out;

  :hover {
    color: #ffffff;
  }

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const MobileHeader = styled("p")`
  text-transform: capitalize;
  font-family: Inter Bold;
  color: #ffffff;
  font-size: 18px;
  line-height: 14px;
  padding-top: 0.5rem;

  @media only screen and (max-width: 810px) {
    font-size: 16px;
  }
`;

export const MobileSpan = styled("p")`
  font-style: normal;
  font-family: Inter Bold;
  font-size: 18px;
  color: #ffb600;
  text-align: center;
  border-bottom: 1px solid;
`;

export const MobileBoldText = styled("p")`
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  font-family: Inter Bold;
  line-height: 17px;
  margin: 0 0 0.5rem 0;
  padding: 0;
`;

export const MobileText = styled("p")`
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
`;

export const MobileAddressText = styled("p")`
  font-size: 16px;
  color: #ffffff !important;
  margin: 0 !important;
`;

export const Copyright = styled("div")`
  padding-top: 2rem;
  width: 100%;
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;

  @media only screen and (max-width: 810px) {
    font-size: 12px;
  }
`;

export const Language = styled("h4")`
  font-size: 22px;
  text-transform: capitalize;
  color: #18216d;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
  }
`;

export const LanguageSwitch = styled("div")<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;
