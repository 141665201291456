import styled, {createGlobalStyle} from "styled-components";

export const Styles = createGlobalStyle`

    @font-face {
        font-family: "Inter Bold";
        src: url("/fonts/Inter-SemiBold.ttf") format("truetype");
    }

    @font-face {
      font-family: "Inter Light";
      src: url("/fonts/Inter-Light.ttf") format("truetype");
      font-weight: 900;
  }

    body,
    html,
    a {
        font-family: 'Inter Light', sans-serif;
    }


    body {
        margin:0;
        padding:0;
        border: 0;
        outline: 0;
        background: #ffffff;
        overflow-x: hidden;
        max-width: 2560px;
        position: relative;
        margin: auto;
    }

    a:hover {
        color: #18216d;
    }

    input,
    textarea {
        border-radius: 4px;
        border: 0;
        background: rgb(241, 242, 243);
        transition: all 0.3s ease-in-out;  
        outline: none;
        width: 100%;  
        padding: 1rem 1.25rem;

        :focus-within {
            background: none;
            box-shadow: #2e186a 0px 0px 0px 1px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Motiva Sans Bold', serif;
        color: #111111;
        font-size: 56px;
        line-height: 1.18;

        @media only screen and (max-width: 890px) {
          font-size: 47px;
        }
      
        @media only screen and (max-width: 414px) {
          font-size: 32px;
        }
    }

    p {
        color: #313131;
        font-size: 21px;        
        // line-height: 1.41;
    }

    h1 {
        font-weight: 600;
    }

    a {
        text-decoration: none;
        outline: none;
        color: #2E186A;

        :hover {
            color: #2e186a;
        }
    }
    
    *:focus {
        outline: none;
    }

    .about-block-image svg {
        text-align: center;
    }

    .ant-drawer-body {
        display: flex;
        flex-direction: column;
        text-align: left;
        padding-top: 1.5rem;
    }

    .ant-drawer-content-wrapper {
        width: 300px !important;
    }

    .u-about-us {
      @media only screen and (max-width: 1024px) {
        display: flex; 
        justify-content: center;
      }
    }

    .u-pl2 {
      padding-left: 2rem;
    }

    .u-pl3 {
      padding-left: 3rem;
    }

    .u-pl4 {
      padding-left: 4rem;
    }

    .u-pr2 {
      padding-right: 2rem;
    }

    .u-pb1 {
      padding-bottom: 1rem;
    }

    .u-pb2 {
      padding-bottom: 2rem;
    }

    .u-pb3 {
      padding-bottom: 3rem;
    }

    .u-pb4 {
      padding-bottom: 4rem;
    }

    .u-pt2 {
      padding-top: 2rem;
    }

    .u-pt3 {
      padding-top: 3rem;
    }

    .u-pt4 {
      padding-top: 4rem;
    }

    .u-mb1 {
      margin-bottom: 1rem;
    }

    .u-mb3 {
      margin-bottom: 3rem;
    }

    .u-pt60 {
      padding-top: 60px;
    }

    .u-text-center {
      text-align: center
    }

    .u-text-left {
      text-align: left
    }

    .u-text-right {
      text-align: right
    }

    .u-align-self-center {
      align-self: center;
    }

    .u-flex-center {
      display: flex;
      align-self: center;
    }

    .video-responsive {
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      height: 0;
    }
    
    .video-responsive iframe {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }

    .u-border-right {
      border-right: 1px solid #D6D6D6
    }

    .u-pointer {
      pointer-events: none;
    }

    .u-pin {
      filter: contrast(0);

      :hover {
        // filter: invert(1%) sepia(23%) saturate(2549%) hue-rotate(330deg) brightness(93%) contrast(105%);
      }
    }

    .u-pin-selected {
      filter: invert(1%) sepia(23%) saturate(2549%) hue-rotate(330deg) brightness(93%) contrast(105%);
    }

    .u-pin-black {
      filter: brightness(0.2);
    }

    .u-location-detail {
      filter: brightness(0);
    }

    .u-position-detail {
      display: none;
      z-index: 1;
    }

    .u-position-detail-selected {
      display: block;
      position: absolute;
    }

    .ant-menu {
      justify-content: space-around;
      font-size: 18px;
      color: #989898;
      border-bottom: 0;
    }

    .ant-menu-item-selected {
      color: #111 !important;
      border-bottom: 0 !important;
    }

    .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after {
      border: 0 !important;
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: transparent;
    }

    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
      border-right: none;
    }

    .ant-menu-overflow-item {
      border: 0 !important;
    }

    .ant-menu-horizontal > .ant-menu-item::after {
      transition: none;
    }

    .ant-menu-item-active {
      border: 0 !important;
      color: #FFB600 !important;
      font-weight: bold;
    }

    .ant-menu-item-active::after {
      border: 0 !important;
    }

    .ant-input:focus {
      border-color: #FFB600;
      outline: 0;
      box-shadow: none !important;
      background: #fff !important;
    }

    .ant-input:hover {
      border-color: #FFB600;
    }

    .u-menu-item {
      // padding-right: 0 !important;
      // margin-left: 1rem !important;
      font-size: 20px !important;
    }

    .u-menu-item-mobile {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 1rem;
      font-size: 13px !important;
    }

    .u-header {
      background: #fff;
      padding: 0;
      display: flex;
      align-items: center;
    }

    .loading-loader {
      top: 45%;
      position: relative;
      text-align: center;
      font-size: 28px;
    }

    .loading-container {
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 99999999 !important;
        background-color: rgba(138, 138, 138, 0.3) !important;
    },

    .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
      padding: 1rem 0;
      font-size: 18px;
    }

    .ant-collapse > .ant-collapse-item > .ant-collapse-header { 
      padding: 1rem 0;
      font-size: 18px;
    }

    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding: 1rem 0;
    }

    .u-button: hover {
      cursor: pointer;
      filter: drop-shadow(2px 4px 6px black);
    }

    .u-input-button {
      width: 195px; 
      height: 61px; 
      border: 0;
      padding: 0;
      margin: 0 0 1rem 0;
      background: transparent;
    }
    
    .carousel .control-dots {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .carousel .control-dots .dot {
      background: #c4c4c4 !important;
      box-shadow: none !important;
      margin: 0 4px !important;
    }

    .carousel .control-dots .dot.selected {
      background-color: #ffb600 !important;
      width: 15px;
      height: 15px;
      box-shadow: none !important;
      margin: 0 4px !important;
    }

    .carousel.carousel-slider .control-arrow {
      background-color: #ffb600 !important;
      padding: 15px !important;
    }

    .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
      opacity: 1 !important;
      margin: 3rem 0;
    }

    .carousel .control-next.control-arrow:before {
      border-left: 12px solid #111 !important;
    }

    .carousel .control-prev.control-arrow:before {
      border-right: 12px solid #111 !important;
    }

    .slide {
      opacity: .4 !important;
    }

    .selected {
      opacity: 1 !important;
    }
`;

export const WebView = styled("div")`
  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const MobileView = styled("div")`
  @media only screen and (max-width: 890px) {
    display: block;
  }

  display: none;
`;

export const Header = styled("p")<any>`
  font-size: 40px;
  font-family: Inter Bold;
  letter-spacing: -1px;
  font-style: normal;
  padding: 1rem 0;
  margin: 0;
  text-align: ${(p) => (p.position ? p.position : "center")};
  color: #111;

  @media only screen and (max-width: 890px) {
    font-size: 22px;
    text-align: left;
    margin-bottom: 1rem;
    letter-spacing: -0.5px;
  }
`;

export const Title = styled("p")<any>`
  font-family: Inter Bold;
  font-size: 32px;
  line-height: 40px;
  padding-top: 2rem;
  letter-spacing: -1px;
  margin: ${(p) => p.margin};
  color: ${(p) => (p.black ? "#111111" : "#313131")};
  text-align: ${(p) => (p.position ? p.position : "center")};

  @media only screen and (max-width: 890px) {
    padding-top: 0.5rem;
    font-size: 22px;
  }
`;

export const GreyBackground = styled("div")<any>`
  background: #f5f5f5;
  padding: ${(p) => (p.padding ? p.padding : "0 0 2rem 0")};
`;

export const HeaderBreakLine = styled("hr")`
  height: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  @media only screen and (max-width: 890px) {
    display: none;
  }
`;

export const BreakLine = styled("hr")`
  height: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 1rem;
`;
